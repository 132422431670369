import { AddPaymentMethod } from '../modalsComponents/AddPaymentMethod/AddPaymentMethod';
import { Header } from '../Header/Header';
import { NavLeft } from '../NavLeft/NavLeft';
import styles from './MainLayout.module.scss';
import {FC, PropsWithChildren, useEffect, MouseEvent} from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { BiLogoTelegram } from "react-icons/bi";


export const MainLayout: FC<PropsWithChildren<unknown>> = ({children}) => {

    const {isNonePaymentMethod} = useAppSelector((state) => state.navigation);
    const {user} = useAppSelector((state) => state.user);

    const handleClick = (e: MouseEvent<SVGElement>, link: string) => {
        e.preventDefault();
        window.open(link);
    } 

    return <>
        <div className={styles.main_container}>
            <NavLeft/>
            <div className={styles.container}>
                <Header/>
                {isNonePaymentMethod && <AddPaymentMethod />}
                {children}
                <BiLogoTelegram className={styles.telegram} onClick={(e) => handleClick(e, 'https://t.me/silverbro_yt')}/>
            </div>
        </div>
    </>   

}