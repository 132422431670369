import { useState } from "react";
import { mAxios } from "../../../utils/api";

export const useSaveTrackStatistics = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const saveTrackStatics = async (chainId: number) => {
        setIsLoading(true);
        try {
            await mAxios.post(`track-statics/updateStaticsTrack/${chainId}`);
            setIsLoading(false);
            window.location.reload();
        } catch (error) {
            setIsLoading(false);
            //notification.error({message: parseError(error), duration: 3});
        }
    }

    return {saveTrackStatics, isLoading}
}