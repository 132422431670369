import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPost } from "../../types/types";
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";
import { ICreatePostDto } from "../../types/dto";
import { notification } from "antd";

interface IBlogState {
    posts: Array<IPost>,
    isLoading: boolean,
    currentPost: IPost
}

const initialState: IBlogState = {
    posts: [],
    isLoading: false,
    currentPost: {} as IPost
}


export const getPosts = createAsyncThunk(
    'get all posts',
    async () => {
        try {
            const { data } = await mAxios.get<Array<IPost>>('blog/all');
            return data;
        } catch (error) {
            //notification.error({ message: parseError(error), duration: 3 });
        }
    }
);


export const getPostById = createAsyncThunk(
    'get post by id',
    async (postId: number) => {
        try {
            const { data } = await mAxios.get<IPost>(`blog/post/${postId}`);
            return data;
        } catch (error) {
            //notification.error({ message: parseError(error), duration: 3 });
        }
    }
)


export const getPostsBySectionId = createAsyncThunk(
    'get posts by section id',
    async (sectionId: number) => {
        try {
            const {data} = await mAxios.get<Array<IPost>>(`blog/postBySection/${sectionId}`)
            return data;
        } catch (error) {
            //notification.error({ message: parseError(error), duration: 3 });
        }
    }
);


///!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// export const postTestCreate = createAsyncThunk(
//     'create post',
//     async (dto: ICreatePostDto) => {
//         try {
//             await mAxios.post('blog/create/2', dto);
//             notification.error({ message: parseError('Статья успешно опубликована!'), duration: 3 });
//         } catch (error) {
//             notification.error({ message: parseError(error), duration: 3 });
//         }
//     }
// );

const BlogSlice = createSlice({
    name: 'blog slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPosts.pending, (state) => {
            state.isLoading = true;
        }),
            builder.addCase(getPosts.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.posts = action.payload : state.posts;
            }),
            builder.addCase(getPosts.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(getPostsBySectionId.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(getPostsBySectionId.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.posts = action.payload : state.posts;
            }),
            builder.addCase(getPostsBySectionId.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(getPostById.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(getPostById.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.currentPost = action.payload : state.currentPost;
            }),
            builder.addCase(getPostById.rejected, (state) => {
                state.isLoading = false;
            })
    }
});

export default BlogSlice.reducer;