import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IChain, IStatisticsContent, IViewsCount } from "../../types/types";
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";
import { notification } from "antd";


interface IChainsState {
    chains: Array<IChain>
    isLoading: boolean,
    viewsCount: IStatisticsContent,
    isPostRedFlag: boolean
}

const initialState: IChainsState = {
    chains: [],
    isLoading: false,
    viewsCount: {} as IStatisticsContent,
    isPostRedFlag: false
}

export const getMyChains = createAsyncThunk(
    'GET MY CHAINS',
    async () => {
        try {
            const { data } = await mAxios.get<Array<IChain>>('/track-chain/my');
            return data;
        } catch (error) {
            //alert(parseError(error));
        }
    }
)

export const getMyAllViews = createAsyncThunk(
    'GET MY ALL VEIWS',
    async () => {
        try {
            const { data } = await mAxios.get<IStatisticsContent>('/track-chain/myViewsCount');
            return data;
        } catch (error) {
            //alert(parseError(error));
        }
    }
)


export const postRedFlag = createAsyncThunk(
    'POST RED FLAG',
    async (chainId: number) => {
        try {
            await mAxios.post(`/track-chain/redFlag/${chainId}`);
            notification.success({message: 'Заявка отправлена!', duration: 2});
        } catch (error) {
            //alert(parseError(error));
        }
    }
)


const chainsSlice = createSlice({
    name: 'chains',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMyChains.pending, (state) => {
            state.isLoading = true;
        }),
            builder.addCase(getMyChains.fulfilled, (state, action) => {
                state.isLoading = false;
                action.payload != undefined ? state.chains = action.payload : state.chains = [];
            }),
            builder.addCase(getMyChains.rejected, (state) => {
                state.isLoading = false;
            }),


            builder.addCase(getMyAllViews.fulfilled, (state, action) => {
                action.payload != undefined ? state.viewsCount = action.payload : state.viewsCount;
            }),


            builder.addCase(postRedFlag.pending, (state) => {
                state.isPostRedFlag = true;
            }),
            builder.addCase(postRedFlag.fulfilled, (state) => {
                state.isPostRedFlag = false;
            }),
            builder.addCase(postRedFlag.rejected, (state) => {
                state.isPostRedFlag = false;

            })
    }
});

export default chainsSlice.reducer;