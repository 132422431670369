import styles from './Login.module.scss';
import { FC, useState, ChangeEvent, useEffect, MouseEvent } from 'react';
import { AiOutlineLogin, AiOutlineLock } from "react-icons/ai";
import { mAxios } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox } from 'antd';
import { BiLogoTelegram } from 'react-icons/bi';


export const Login: FC = () => {

    const navigate = useNavigate();

    const [isLoading, setisLoading] = useState(false);
    const [userAgreement, setUserAgreement] = useState<boolean>(true);

    const [values, setValues] = useState({
        nick: '',
        password: ''
    });

    const valuesHandler = (e: ChangeEvent<HTMLInputElement>) => setValues({ ...values, [e.target.name]: e.target.value });

    useEffect(() => {
        if(localStorage.getItem('token')){
            navigate('/');
        }
    },[]);

    const submitForm = async() => {
        setisLoading(true);
        try {
            const {data} = await mAxios.post('auth/login', values);
            localStorage.setItem('token', data.token);
            setisLoading(false);
            window.location.reload();
        } catch (error) {
            setisLoading(false);
        }
    }

    const handleClick = (e: MouseEvent<HTMLSpanElement> | MouseEvent<SVGElement>, link: string) => {
        e.preventDefault();
        window.open(link);
    }

    return <>
        <div className={styles.container}>

            {/*<div className={styles.scroll_text}>*/}
            {/*    <div>*/}
            {/*        <span>SILVER </span> BRO <span>- SILVER </span> BRO <span>- SILVER </span> BRO*/}
            {/*        <span>SILVER </span> BRO <span>- SILVER </span> BRO <span>- SILVER </span> BRO*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className={styles.scroll_text_2}>*/}
            {/*    <div>*/}
            {/*        <span>SILVER </span> BRO <span>- SILVER </span> BRO <span>- SILVER </span> BRO*/}
            {/*        <span>SILVER </span> BRO <span>- SILVER </span> BRO <span>- SILVER </span> BRO*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={styles.form}>
                <h1>SILVER BRO</h1>

                <div className={styles.inputs_container}>
                    <div className={styles.input_container}>
                        <AiOutlineLogin size={20} />
                        <input placeholder='Логин' maxLength={50} name='nick' onChange={(e) => valuesHandler(e)}/>
                    </div>
                    <div className={styles.input_container}>
                        <AiOutlineLock size={20} />
                        <input placeholder='Пароль' maxLength={50} name='password' onChange={(e) => valuesHandler(e)} type='password'/>
                    </div>
                </div>

                <div className={styles.checkbox_container}>
                    <Checkbox className={styles.check_box} onChange={(e) => setUserAgreement(!e.target.checked)}/>
                    <p className={styles.txt_desc}>Принимаю условия <span onClick={(e) => handleClick(e, 'https://docs.google.com/document/d/1RI6ns0FBbF5x1ejMpFEn0IprbN6JXteE')}>пользовательского соглашения</span> 
                        {/* и даю своё согласие на обработку персональной информации на условиях, определённых <span onClick={(e) => handleClick(e, 'https://docs.google.com/document/d/1RI6ns0FBbF5x1ejMpFEn0IprbN6JXteE')}>политикой конфиденциальности</span> */}
                    </p>
                </div>

                <Button loading={isLoading} onClick={() => submitForm()} disabled={userAgreement}>Войти</Button>

            </div>
            <BiLogoTelegram className={styles.telegram} onClick={(e) => handleClick(e, 'https://t.me/silverbro_yt')}/>
        </div>
    </>

}
