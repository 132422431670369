import { FC, useRef, useState } from 'react';
import styles from './TrackItem.module.scss';
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import { PiSpeakerHighLight, PiPlayDuotone, PiPauseDuotone, PiDownloadSimpleBold, PiPlusCircleDuotone, PiFlagBold } from "react-icons/pi";
import { IChain } from '../../../types/types';
import { ViewVideoByTrackModal } from '../../modalsComponents/ViewVideosByTrackModal/ViewVideoByTrackModal';
import { AddNewVideoModal } from '../../modalsComponents/addNewVideoModal/AddNewVideoModal';
import { MdMusicOff } from "react-icons/md";
import { AddRedFlagModal } from '../../modalsComponents/addRedFlagModal/AddRedFlagModal';
import {Button, notification, Spin} from 'antd';
import { MdOutlineAttachMoney } from "react-icons/md";
import { mAxios } from '../../../utils/api';
import { BASE_URL, parseError } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import {antIcon} from "../../CustomSpin/CustomSpin";
import {LoadingOutlined} from "@ant-design/icons";
import { SaveTrackStatisticsBtn } from '../../../features/saveTrackStatistics';




interface ITrackItemProps {
    chain: IChain
}


export const TrackItem: FC<ITrackItemProps> = ({ chain }) => {

    const [isOpen, setIsOpen] = useState(false);

    const [isOpenAddVideo, setIsOpenAddVideo] = useState(false);
    const [isOpenRed, setIsOpenRed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloadTrack, setIsDownloadTrack] = useState(false);

    const [play, setPlay] = useState(false);
    const playerRef = useRef<HTMLAudioElement>(null);
    const MAX = 20;

    const toggleAudio = () => {
        if (play) {
            playerRef.current?.pause();
            setPlay(false);
        } else {
            playerRef.current?.play();
            setPlay(true);
        }
    }

    const handleVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const volume = Number(value) / MAX;
        if (playerRef.current != null) {
            playerRef.current.volume = volume;
        }
    }

    const getPaymentForTheTrack = async () => {
        setIsLoading(true);
        try {
            await mAxios.post(`/receiving-funds/createGray/${chain.id}`);
            setIsLoading(false);
            notification.success({ message: 'Заявка успешно отправлена!', duration: 3 });
        } catch (error) {
            setIsLoading(false);
            //notification.error({message: parseError(error), duration: 3});
        }
    }

    const downloadTrack = async () => {
        setIsDownloadTrack(true)
        try {
            mAxios.get(`track/download/${chain.track?.id}`, {
                responseType: 'blob'
            })
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = chain.track.originalName;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }).finally(() => setIsDownloadTrack(false))
        } catch (error) {
            setIsDownloadTrack(false)
        }
    }

    return <>
        <div className={`${styles.container} ${chain.redFlag && styles.redFlag}`}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className={styles.left_container}>
                    <div className={styles.music_info}>
                        {chain.redFlag ? <PiFlagBold size={25} className={styles.flag} /> : <PiSoundcloudLogoDuotone size={25} className={styles.icon} />}
                        <div>
                            <p className={styles.title}>{chain.track.originalName}</p>
                            <p className={styles.other}>ID: {chain.track.id}</p>
                            <p className={styles.other_transform}>Просмотры: {chain.statistics?.lastSeenSave ?? '0'}</p>

                            <div className={styles.video_info_container}>
                                <p className={styles.other_transform}>Видео: {chain.videos.length}</p>
                                {
                                    !chain.redFlag && <button onClick={() => setIsOpenAddVideo(true)}>Добавить</button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={styles.data_container}>
                        <div className={styles.views_container}>
                            <p><span>Просмотры:</span> {chain.statistics?.lastSeenSave ?? '0'}</p>
                            {/* <PiSmileyWink size={25} className={styles.icon} /> */}
                            <SaveTrackStatisticsBtn chainId={chain.id}/>
                        </div>

                        <div className={styles.videos_container}>
                            {/* <PiYoutubeLogoDuotone size={25} className={styles.icon} /> */}
                            <p onClick={() => setIsOpen(true)}>{chain.videos.length} видео</p>
                            <div className={styles.btn_add} onClick={() => setIsOpenAddVideo(true)}>
                                {
                                    !chain.redFlag && <PiPlusCircleDuotone className={styles.icon} />
                                }
                            </div>
                        </div>

                        <div className={styles.sound_container}>
                            <audio ref={playerRef} loop src={`${BASE_URL}/uploads_track/${chain?.track?.fileName}`} />
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <PiSpeakerHighLight size={25} color='black' />
                                <input type='range' min={0} max={MAX} onChange={(e) => handleVolume(e)} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className={styles.btn_container}>
                    {/* toggleAudio() */}
                    <div onClick={() => toggleAudio()}>
                        {
                            play ? <PiPauseDuotone size={25} className={styles.btn} /> : <PiPlayDuotone size={25} className={styles.btn} />
                        }
                    </div>

                    {
                        !chain.redFlag && <>
                            {
                                !isDownloadTrack ?
                                <PiDownloadSimpleBold size={25} className={styles.btn_download} onClick={() => downloadTrack()}/> : <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#ffa600' }} spin />} />
                            }

                            <div onClick={() => setIsOpenRed(true)}>
                                <MdMusicOff size={25} className={styles.options} />
                            </div>
                        </>
                    }
                </div>
            </div>

            <Button className={styles.get_paymen_btn} loading={isLoading} onClick={() => getPaymentForTheTrack()} icon={<MdOutlineAttachMoney size={15} />}>запросить выплату</Button>
        </div>
        {isOpen && <ViewVideoByTrackModal track={chain.track} videos={chain.videos} onClose={() => setIsOpen(false)} />}
        {isOpenAddVideo && <AddNewVideoModal chain={chain} onClose={() => setIsOpenAddVideo(false)} />}
        {isOpenRed && <AddRedFlagModal chain={chain} onClose={() => setIsOpenRed(false)} />}
    </>

}
