import { useAppDispatch } from '../../hooks/hooks';
import { deleteMyVideo, postSaveStaticsInVideo } from '../../redux/slices/video';
import { IVideo } from '../../types/types';
import { IYouTube } from '../../types/youtube';
import { apiGetVideoStatics } from '../../utils/api';
import styles from './VideoItem.module.scss';
import { FC, useState, useEffect } from 'react';
import { AiOutlineHeart, AiOutlineComment, AiOutlineEye, AiOutlineSave, AiOutlineDelete } from "react-icons/ai";


interface IVideoItemProps {
    video: IVideo
}


export const VideoItem: FC<IVideoItemProps> = ({ video }) => {

    const [data, setData] = useState<IYouTube>();
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();

    const getInfo = async () => {
        setIsLoading(true);
        try {
            const { data } = await apiGetVideoStatics(video.youTubeId);
            setData(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getInfo();
    }, []);

    console.log(data);

    if (data?.items.length == 0) {
        return <div>Loading...</div>
    }

    return <>
        <div className={styles.container}>
            <div className={styles.section_left}>
                <img src={data?.items[0]?.snippet?.thumbnails?.default.url} className={styles.preview} />
                <div className={styles.data}>
                    <p className={styles.title}>{data?.items[0].snippet.title}</p>

                    <div className={styles.data_container}>
                        <div className={styles.count_container}>
                            <div className={styles.info}>
                                <p className={styles.other}>Лайки: <span>{data?.items[0].statistics.likeCount}</span></p>
                                <AiOutlineHeart size={15} className={styles.icon} />
                            </div>

                            <div className={styles.info}>
                                <p className={styles.other}>Комментарии: <span>{data?.items[0].statistics.commentCount}</span></p>
                                <AiOutlineComment size={15} className={styles.icon} />
                            </div>
                        </div>

                        <div className={styles.count_container}>
                            <div className={styles.info}>
                                <p className={styles.other}>Просмотры: <span>{data?.items[0].statistics.viewCount}</span></p>
                                <AiOutlineEye size={15} className={styles.icon} />
                            </div>

                            <div className={styles.info}>
                                <p className={styles.other}>Сохраненные просмотры: <span>{video.recentViews}</span></p>
                                <AiOutlineSave size={15} className={styles.icon} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.btns_cotnainer}>
                <AiOutlineSave size={25} className={styles.btn_save} onClick={() => dispatch(postSaveStaticsInVideo(video.id))} />
                <AiOutlineDelete size={25} className={styles.btn_delete} onClick={() => dispatch(deleteMyVideo(video.id))} />
            </div>
        </div>
    </>

}