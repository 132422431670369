import axios from "axios";
import { IYouTubeChannel } from "../types/youtube_channel";
import { notification } from "antd";
import { parseError } from "./utils";

export const youtubeAxios = axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3',
    params: {
        part: 'statistics',
        maxResults: 300,
        key: 'AIzaSyAwlEWMoK7tr3hFqDY8phc-9VePMexQQ9M'
    }
})

export const mAxios = axios.create({
    //withCredentials: true,
    baseURL: 'https://silverbroserver.ru/',
    //baseURL: 'http://localhost:5000/',
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});

mAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status == 401) {
            localStorage.removeItem('token');
            window.location.reload();
        }
        notification.error({message: parseError(error), duration: 3});
        return Promise.reject(error);
    }
);


export const apiGetVideoStatics = async (videoId: string) => {
    return await axios.get(`https://www.googleapis.com/youtube/v3/videos?part=statistics,snippet&id=${videoId}&key=AIzaSyAwlEWMoK7tr3hFqDY8phc-9VePMexQQ9M`);
}


export const apiGetChannelInform = async (channelId: string) => {
    return await axios.get<IYouTubeChannel>(`https://www.googleapis.com/youtube/v3/channels?part=statistics,snippet&id=${channelId}&key=AIzaSyAwlEWMoK7tr3hFqDY8phc-9VePMexQQ9M`)
} 