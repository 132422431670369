import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IInvoice } from "../../types/types";
import { AddPaymentMethodCardDto, AddPaymentMethodUsdt } from "../../types/dto";
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";
import { notification } from "antd";



interface IPaymentMethodState {
    invoices: Array<IInvoice>,
    isLoading: boolean,
    isUploading: boolean
}

const initialState: IPaymentMethodState = {
    invoices: [],
    isLoading: false,
    isUploading: false
}


export const postNewPaymentMethod = createAsyncThunk(
    'add new payment method',
    async (dto: AddPaymentMethodCardDto) => {
        try {
            await mAxios.post('payment-method/addPaymentMethodTinkoff', dto);
            window.location.reload();
        } catch (error) {
            //notification.error({message: parseError(error), duration: 3});
        }
    }
);

export const postNewPaymentMethodUsdt = createAsyncThunk(
    'add new payment method usdt',
    async (dto: AddPaymentMethodUsdt) => {
        try {
            await mAxios.post('payment-method/addPaymentMethodUsdt', dto);
            window.location.reload();
        } catch (error) {
            //notification.error({message: parseError(error), duration: 3});
        }
    }
);

export const getMyPaymentsMethod = createAsyncThunk(
    'get my payments method',
    async () => {
        try {
            const {data} = await mAxios.get<Array<IInvoice>>('payment-method/my');
            return data;
        } catch (error) {
            //notification.error({message: parseError(error), duration: 3});
        }
    }
);

const paymentMethodSlice = createSlice({
    name: 'paymentMethod',
    initialState, 
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postNewPaymentMethod.pending, (state) => {
            state.isUploading = true;
        }),
        builder.addCase(postNewPaymentMethod.fulfilled, (state) => {
            state.isUploading = false;
        }),
        builder.addCase(postNewPaymentMethod.rejected, (state) => {
            state.isUploading = false;
        }),


        builder.addCase(postNewPaymentMethodUsdt.pending, (state) => {
            state.isLoading = true;
        }),
        builder.addCase(postNewPaymentMethodUsdt.fulfilled, (state, action) => {
            state.isLoading = false;
        }),
        builder.addCase(postNewPaymentMethodUsdt.rejected, (state) => {
            state.isLoading = false;
        })


        builder.addCase(getMyPaymentsMethod.pending, (state) => {
            state.isLoading = true;
        }),
        builder.addCase(getMyPaymentsMethod.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.invoices = action.payload : state.invoices;
        }),
        builder.addCase(getMyPaymentsMethod.rejected, (state) => {
            state.isLoading = false;
        })

    }
})


export default paymentMethodSlice.reducer;