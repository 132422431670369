import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";
import { ISection } from "../../types/types";

interface ISections {
    sections: Array<ISection>,
    isLoading: boolean
}

const initialState: ISections = {
    sections: [],
    isLoading: false
}


export const getAllSections = createAsyncThunk(
    'get all sections',
    async () => {
        try {
            const {data} = await mAxios.get<Array<ISection>>('blog-sections/all');
            return data;
        } catch (error) {
            //alert(parseError(error));
        }
    }
);


const sectionsSlice = createSlice({
    name: 'sections slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllSections.pending, (state) => {
            state.isLoading = true;
        }),
        builder.addCase(getAllSections.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.sections = action.payload: state.sections;
        }),
        builder.addCase(getAllSections.rejected, (state) => {
            state.isLoading = false;
        })
    }
});


export default sectionsSlice.reducer;