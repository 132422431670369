import {FC, useState} from 'react';
import { mAxios } from '../../utils/api';
import { Button, notification } from 'antd';
import { parseError } from '../../utils/utils';
import { IInvoice } from '../../types/types';
import bg from '../../assets/usdt_bg.svg';
import styles from './UsdtCardItem.module.scss';
import usdt from '../../assets/usdt_logo.svg';


export interface ICardProps {
    invoice: IInvoice
}


export const UsdtCardItem: FC<ICardProps> = ({invoice}) => {

    const [isDeleted, setIsDeleted] = useState(false);

    const deleteCurrentInvoice = async(cardId: number) => {
        setIsDeleted(true);
        try {
            await mAxios.delete(`payment-method/my/${cardId}`);
            setIsDeleted(false);
            window.location.reload();
        } catch (error) {
            //notification.error({message: parseError(error), duration: 3});
            setIsDeleted(false);
        }
    }

    return <>
        <div className={styles.container}>
            <img src={bg} className={styles.bg} />

            <div className={styles.data_container}>
                <div className={styles.header}>
                    <p>{invoice.nameMethod}</p>
                    <img src={usdt} />
                </div>

                <div>
                    <p className={styles.card_number}>{invoice.invoice}</p>
                </div>
            </div>
    
            {/* <IoIosRemoveCircleOutline className={styles.close_icon}/> */}
        
            <Button className={styles.delete_btn} loading={isDeleted} onClick={() => deleteCurrentInvoice(invoice.id)}>Удалить</Button>
        </div>
    </>

}