import { FC, useState } from 'react';
import styles from './ContentTrackItem.module.scss';
import { PiSoundcloudLogoDuotone } from "react-icons/pi";
import { AiOutlineSave } from "react-icons/ai";
import { IChain } from '../../types/types';
import { mAxios } from '../../utils/api';
import { convertDateAndTime, parseError } from '../../utils/utils';
import { CustomSpin } from '../CustomSpin/CustomSpin';
import { Button, notification } from 'antd';
import { SaveTrackStatisticsBtn } from '../../features/saveTrackStatistics';


interface IContentTrackItemProps {
    chain: IChain
}


export const ContentTrackItem: FC<IContentTrackItemProps> = ({ chain }) => {

    const { track, statistics } = chain;

    return <>
        <div className={styles.container}>
            <div className={styles.container_container}>
                <PiSoundcloudLogoDuotone size={25} />

                <div className={styles.data_container}>
                    <div className={styles.track_info}>
                        <p className={styles.title}>{track.originalName}</p>
                        <p className={styles.other}>ID: {track.id}</p>
                    </div>

                    <div className={styles.view_coomerce_count}>
                        <p>?</p>
                    </div>
                    <div className={styles.view_count}>
                        <p>{statistics?.lastSeenSave ?? 0}</p>
                    </div>
                    <div className={styles.view_coomerce_count}>
                        <p>?</p>
                    </div>
                </div>

                <SaveTrackStatisticsBtn chainId={chain.id}/>
            </div>

            <p className={styles.last_time_save}>Последнее сохранение: {chain.statistics != null ? convertDateAndTime(chain.statistics?.lastDateSave) : 'отсутствует'}</p>
        </div>
    </>

}