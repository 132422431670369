import { FC, memo } from "react";
import { useSaveTrackStatistics } from "../hooks/useSaveTrackStatistics";
import { RxUpdate } from "react-icons/rx";
import styles from './SaveTrackStatisticsBtn.module.scss'
import { CustomSpin } from "../../../components/CustomSpin/CustomSpin";

interface ISaveTrackStatisticsProps {
    chainId: number
}

export const SaveTrackStatisticsBtn: FC<ISaveTrackStatisticsProps> = memo(({chainId}) => {
    const {isLoading, saveTrackStatics} = useSaveTrackStatistics()
    
    return <div onClick={() => saveTrackStatics(chainId)} className={styles.container}>
        <RxUpdate className={isLoading ? styles.isLoading: ''} style={{height: '100%', width:'100%'}}/>
    </div>
})