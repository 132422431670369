import { IVideo } from "../../types/types";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";
import { AddVideoLinkDto } from "../../types/dto";
import { notification } from "antd";


interface IVideoState {
    videos: Array<IVideo>;
    isLoading: boolean;
    isUploadingVideo: boolean;
}

const initialState: IVideoState = {
    videos: [],
    isLoading: false,
    isUploadingVideo: false
}

export const getMyVideos = createAsyncThunk(
    'get my videos',
    async () => {
        try {
            const { data } = await mAxios.get<Array<IVideo>>('videolinks/my');
            return data;
        } catch (error) {
            //alert(parseError(error));
        }
    }
);

export const addLink = createAsyncThunk(
    'add link to account',
    async (dto: AddVideoLinkDto) => {
        try {
            await mAxios.post('videolinks/add_video_link', dto);
        } catch (error) {
            //notification.error({ message: parseError(error), duration: 3 });
        }
    }
);

export const postSaveStaticsInVideo = createAsyncThunk(
    'save statistics video',
    async (videoId: number) => {
        try {
            await mAxios.post(`videolinks/saveStatisticsVideo/${videoId}`);
            //notification.success({message: 'Статистика была успешно сохранена!', duration: 3});
            window.location.reload();
        } catch (error) {
            //notification.error({ message: parseError(error), duration: 3 });
        }
    }
);

export const deleteMyVideo = createAsyncThunk(
    'delete my video',
    async (videoId: number) => {
        try {
            await mAxios.delete(`videolinks/deleteMyVideo/${videoId}`);
            //notification.success({message: 'Статистика была успешно сохранена!', duration: 3});
            window.location.reload();
        } catch (error) {
            //notification.error({ message: parseError(error), duration: 3 });
        }
    }
);

const videosSlice = createSlice({
    name: 'videos',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMyVideos.pending, (state) => {
            state.videos = [];
            state.isLoading = true;
        }),
            builder.addCase(getMyVideos.fulfilled, (state, action) => {
                action.payload != undefined ? state.videos = action.payload : state.videos = [];
                state.isLoading = false;
            }),
            builder.addCase(getMyVideos.rejected, (state) => {
                state.isLoading = false;
            })


        builder.addCase(addLink.pending, (state) => {
            state.isUploadingVideo = true;
        }),
            builder.addCase(addLink.fulfilled, (state) => {
                state.isUploadingVideo = false;
                window.location.reload();
            }),
            builder.addCase(addLink.rejected, (state) => {
                state.isUploadingVideo = false;
            })
        }
});

export default videosSlice.reducer;