import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITransaction } from "../../types/types";
import { mAxios } from "../../utils/api";
import { parseError } from "../../utils/utils";


interface ITransactionsState {
    isLoading: boolean,
    transactions: Array<ITransaction>
}

const initialState: ITransactionsState = {
    isLoading: false,
    transactions: []
}

export const getMyTransactions = createAsyncThunk(
    'get my transactions',
    async () => {
        try {
            const {data} = await mAxios.get<Array<ITransaction>>('transactions/my');
            return data;
        } catch (error) {
            //alert(parseError(error));
        }
    }
);


const transactionsSlice = createSlice({
    name: 'transactions slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMyTransactions.pending, (state) => {
            state.isLoading = true;
        }),
        builder.addCase(getMyTransactions.fulfilled, (state, action) => {
            state.isLoading = false;
            action.payload != undefined ? state.transactions = action.payload : state.transactions;
        }),
        builder.addCase(getMyTransactions.rejected, (state) => {
            state.isLoading = false;         
        })
    }
});


export default transactionsSlice.reducer;